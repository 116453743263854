@media (min-width: 1300px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1240px;
  }
}

@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1340px;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}

@media (min-width: 1700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1540px;
  }
}

@media (min-width: 1800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1640px;
  }
}

@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1740px;
  }
  .socialSingin::before, .socialSingin::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 7rem !important;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
}
}

@media (max-width: 1599px) {
  .btn {
    font-size: 0.8rem !important;
  }
  .btn-action {
    font-size: 0.8rem !important;
  }
  .bannerHeading {
    font-size: 60px;
  }
  .bannerHeading2 {
    font-size: 50px !important;
  }
  .bannerHeading3 {
    font-size: 20px !important;
    color: #474e58;
  }
  h1 {
    font-size: 26px;
  }

  .socialSingin::before, .socialSingin::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 5rem !important;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
}

  h2 {
    font-size: 22px;
  }
  .homeText {
    font-size: 20px;
  }
  .homeText2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 15px;
  }

  .h6,
  h6,
  p,
  ul,
  li,
  a,
  span,
  .text-md {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }
}

@media (max-width: 1400px) {
  .overlayText:before {
    font-size: 50px;
    top: -50px;
  }

  .btn {
    font-size: 0.8rem !important;
  }
  .btn-action {
    font-size: 0.7rem !important;
  }
  .carousel .control-dots {
    bottom: -40px !important;
  }
  h1 {
    font-size: 22px;
  }
  .bannerHeading {
    font-size: 54px;
  }
  .homeHeadingText {
    font-size: 30px;
    line-height: 33px;
  }
  .bannerHeading2 {
    font-size: 40px !important;
  }
  .bannerHeading3 {
    font-size: 18px !important;
    color: #474e58;
  }
  h2 {
    font-size: 20px;
  }
  .homeText2 {
    font-size: 20px;
  }
  .homeText {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  .h6,
  h6,
  p,
  ul,
  li,
  a,
  span,
  .text-md {
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
  }

  .file-upload {
    height: 200px;
  }

  .eventChangebtn {
    border-radius: 5px !important;
    box-shadow: none !important;
    float: right;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 0px;
    margin-top: 20px;
    min-width: 140px !important;
    outline: 0;
    background: #fff;
    border: 1px solid #484848;
    color: #484848;
    transition: 0.5s;
  }

  .outlin_oran_but,
  .outlin_blue_but {
    font-size: 14px;
  }

  .create-event-form-input {
    border: 1px solid #e4e5e7 !important;
    border-radius: 8px !important;
    color: #222325 !important;
    cursor: pointer;
    font-weight: 400;
    min-height: 40px;
    font-size: 14px;
    outline: 0;
  }

  .asset-gallery-bg img {
    height: 35px;
    object-fit: contain;
    padding: 2px;
    width: 100%;
  }

  .btn_radio + label {
    min-height: 40px;
    transition: 0.5s;
  }

  .full-img-div {
    background: #cccccc6b;
    padding: 15px;
    position: relative;
    max-height: 200px;
    min-height: 200px;
    display: flex;
    text-align: center;
  }

  .fullshowimg {
    background: #ccc;
    padding: 15px;
    max-height: 200px;
    min-height: 200px;
    display: flex;
    text-align: center;
  }

  .spinner-gallery {
    background: #ccc;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-of-event {
    max-height: 100px;
    max-width: 100px;
    min-width: 100px;
  }

  .category_select .css-13cymwt-control {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    color: #222325;
    padding: 0px;
    min-height: 40px;
    font-size: 14px;
    box-shadow: 0px 1px 6px #dbdbdb;
  }
  .pro-imgg-lg {
    box-shadow: 0 1px 5px #ccc;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    object-fit: contain;
    padding: 3px;
    width: 92px;
  }
}

@media (max-width: 990px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-list .testimonial-item {
    margin: 5px;
    background: #fff;
    color: #000 !important;
    text-align: center;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 10px;
}
  .min-350{
    min-width: 100%;
  }
  .h5, h5, .text-lg {
    font-size: 14px;
}
.h-90{
  height: 65px !important;
}
.fs-14{
  font-size: 12px !important;
}
  .fs-16 {
    font-size: 14px !important;
    letter-spacing: 0.5px;
    font-family: Open Sans;
    font-weight: 500;
    line-height: 15px;
    opacity: 1;
  }
  .fs-20 {
    font-size: 18px !important;
  }
  .labelHeads{
    font-size: 14px !important;
  }
  .auction-btnn {
    justify-content: center;
    /* clip-path: polygon(0 0%, 117% 0%, 93% 100%, 0% 100%, 0% 0%); */
  }
}



@media (max-width: 1200px) {
  .socialSingin::before,
  .socialSingin::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 5rem !important;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
  }

  .carousel-control-prev-icon {
    background-image: url("../public/assets/images/icons/png/Group\ 446.png");
    width: 70%;
    height: 65px;
  }

  .carousel-control-next-icon {
    background-image: url("../public/assets/images/icons/png/Group\ 461.png");
    width: 70%;
    height: 65px;
  }
  .eventCardBody {
    padding: 0px;
  }
  /* .card-video {
    display: none;
  } */
  .cardLocations {
    display: none;
  }
  .cardLocations2 {
    display: none;
  }
  .cardDates{
    display: none;
  }
  .cardDetailButton {
    border-radius: 0px 0px 15px 0px !important;
  }
  .cardIcons {
    display: none;
  }
  .mobileviewLocations {
    display: flex;
  }
  .cardProcess {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width :750px) {
 
  /* .testi-comment-disc {
    overflow: hidden;
    font-size: 13px;
    width: 88%;
    font-family: Open Sans;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 8;
    -webkit-box-orient: vertical;
  } */

 

  .socialSingin::before, .socialSingin::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 3rem !important;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
    
}

.btn {
  padding: 5px 4px;
}


}
@media (max-width :330px) {
.slick-list {
  padding: 0 !important;
}
}
@media (max-width :315px) {

  .socialSingin::before, .socialSingin::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 2rem !important;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
}
  
}
.detailsProccessMobile{
  display: none !important;
}

@media (max-width: 990px) {
  .eventCardBody {
    padding: 0px;
  }
  .btn-action{
    padding: 5px 7px;
  }
  .detailsProccess{
    display: none !important;
  }
  .detailsProccessMobile{
    display: flex !important;
    justify-content: center;
  }

  .cardContent {
    min-height: 0px;
    /* justify-content: center; */
  }

  .btn-action {
    font-size: 0.6rem;
  }
  .cardLocations {
    display: none;
  }
  .cardSpaceBetween {
    display: flex;
  }

 
  
  .cardIcons {
    display: none;
  }
  .cardProcess {
    display: flex;
    justify-content: space-between;
  }
  .mobileviewIcons {
    display: flex;
    position: absolute;
    right: 5px;
    bottom: -65px;
  }
  .mobileviewshareIcon{
    display: flex;
    position: absolute;
    right: 16px;
    bottom: 20px;
}
  .mobileviewIconseventdetails {
    position: absolute;
    right: 5px;
    bottom: -65px;
  }
  .hoverable-icon {
    position: absolute;
    right: 10px;
    top: 12px;
  }
  .carousel .control-dots {
    bottom: -25px !important;
  }
  .goBack {
    /* background-color: #fff;
		border: 1px solid #fff; */
    display: none;
  }

  h2,
  h3,
  h4,
  .text-xxl {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
  }
  .center .slick-center .testimonial-item {
    transform: none;
  }
  .homeHeadingText {
    font-size: 22px;
    line-height: 30px;
    /* text-align: center; */
  }

  .overlayText:before {
    font-size: 40px;
    top: -35px;
  }
  .homeText {
    font-size: 16px;
  }
  .homeText2 {
    font-size: 18px;
  }
  .bannerHeading {
    font-size: 44px;
  }
  .btn-custom-width {
    min-width: 130px;
  }
  .bannerHeading2 {
    font-size: 30px !important;
  }
  .bannerHeading3 {
    font-size: 16px !important;
    color: #474e58;
  }

  .ul-scroll {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
  }

  .radio-capsule + label {
    white-space: nowrap;
  }

  .event_ml_left,
  .event_ml_right {
    margin: 0px;
  }

  .btn {
    height: 35px;
    line-height: 20px;
    transform: scale(1);
    transition: all 0.3s ease !important;
    font-size: 0.75rem !important;
    font-weight: 700;
    box-shadow: none !important;
    outline: 0px !important;
  }

  .btn-subscribe {
    background: #f2875c;
    border-color: #f2875c;
    color: #fff;
    width: 100px;
    height: 40px;
    border: 0px;
    border-radius: 0 5px 5px 0;
    font-weight: 700;
    font-size: 0.9rem;
    outline: 0;
    box-shadow: none;
  }

  .overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* .sidebar label {
		margin-left: 5px !important;
		margin-top: -2px !important;
		margin-bottom: 15px;
	} */

  .paginationNew .MuiPaginationItem-previousNext {
    font-weight: 700;
    height: 40px;
    width: auto;
  }

  .paginationNew .MuiPaginationItem-page {
    font-weight: 700;
    height: 40px;
    width: 40px;
  }

  .create_event_tabs button .create_no_btn {
    border-radius: 50%;
    font-size: 16px;
    padding: 8px 13px 8px 14px;
  }

  .category_select {
    width: 100%;
  }

  .stepper-tabs button h5 {
    font-size: 12px;
  }

  .event-filter-sidebar {
    display: none;
  }
}

.filterButton {
  padding: 2px 10px;
  margin-right: 15px;
  margin-top: 15px;
  box-shadow: none !important;
  outline: 0;
}

.filterIcon {
  width: 16px;
  margin-right: 5px;
}
